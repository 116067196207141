export const API_URL = process.env.REACT_APP_API_URL;
export const TOKEN_KEY = 'fc-auth-token';
export const USER_KEY = 'fc-username';
export const APP_VERSION = 'v2.2.1';
export const LOGOTIPO_CLIENTE = 'logotipo-cliente';
export const LOGOTIPO_EMPRESA = 'logotipo-empresa';
export const MENUS = 'menu';
export const DASHBOARD_INICIAL = 'dashboard-inicial';
export const TIPO_DOC_EXPO_OBRIGATORIO = 'tipo-doc-expo-obrigatorio';
export const TIPO_DOC_IMPO_OBRIGATORIO = 'tipo-doc-impo-obrigatorio';
export const DASHBOARD_EXPORTACAO_GRAFICOS = 'dashboard-exportacao-graficos';
export const DASHBOARD_IMPORTACAO_GRAFICOS = 'dashboard-importacao-graficos';
export const EXPIRATION_TIME = 24 * 60 * 60 * 1000;
